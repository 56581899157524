<template>
  <a-modal
    title="新增商家违规"
    :width="500"
    :visible="visible"
    :confirmLoading="confirmLoading"
    :destroyOnClose="true"
    @ok="handleSubmit"
    @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <a-row :gutter="24">
        <a-col :md="24" :sm="24">
          <a-form :form="form">
            <!-- 违规信息 -->
            <a-form-item label="违规信息" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
              <a-textarea
                v-decorator="['illegalMessage', { rules: [{ required: true, message: '请输入！' }] }]"
                placeholder="请输入该商家违规信息"
              />
            </a-form-item>
          </a-form>
        </a-col>
      </a-row>
    </a-spin>
  </a-modal>
</template>

<script>
import { 
  storeIllegalMessageAdd
} from '@/api/modular/mallLiving/storeIllegalMessage'
import {uploadFile} from "@/api/modular/mallLiving/uploadFile";

export default {
  data() {
    return {
      loading: false,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      storeId:''
    }
  },

  methods: {
    // 初始化方法
    add(storeId) {
      this.storeId = storeId
      this.visible = true
      this.picture = '' //将图片重置为空
    },
    handleSubmit() {
      this.confirmLoading = true
      this.form.validateFields((errors, values) => {
        if (!errors) {
            storeIllegalMessageAdd({ storeId:this.storeId, ...values }).then((res) => {
              if(res.success){
                this.$message.success("新增成功！")
                this.$emit("ok",values)
                this.confirmLoading = false
                this.handleCancel()
              }else{
                setTimeout(()=>{
                  this.confirmLoading = false
                }, 600)
              }
            })
        } else {
          this.confirmLoading = false
        }
      })
    },
    //图片上传，cos
    getUploadScreat() {
      let that = this
      getUploadScreat().then((res) => {
        that.uploadOption = res.data
        let data = {
          TmpSecretId: res.data.tmpSecretId,
          TmpSecretKey: res.data.tmpSecretKey,
          XCosSecurityToken: res.data.sessionToken,
          StartTime: res.data.startTime,
          ExpiredTime: res.data.expiredTime
        }
        sessionStorage.setItem("upload-screat-msg", JSON.stringify(res.data))
        that.cos = new COS ({
          getAuthorization: function (options, callback) {
            callback (data)
          }
        })
      })
    },
    formatFileName(){
				var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
				a = t.length,
				n = "";
				
				for (var i = 0; i < 10; i++) n += t.charAt(Math.floor(Math.random() * a));
				return n + new Date().getTime()
			},
    //自定义图片上传实现
    customUserRequest(filedata) {
      this.pictureLoading = true
      uploadFile(filedata.file.name, filedata.file, {
        progress: function (p, checkpoint) {
          const percentage = Math.floor(p * 100)
        },
      }).then((res) => {
        console.log('res', res)
        if (res.res.statusCode == 200) {
          const list = res.res.requestUrls[0].split('?');
          if (list.length > 0) {
            this.picture = list[0]
            this.pictureLoading = false
          }
        }
      })
    },
    handleCancel() {
      this.visible = false //关闭对话框
      this.confirmLoading = false
      this.form.resetFields() //重置表单
    }
  },
}
</script>
<style scoped>
.flex {
  display: flex;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>